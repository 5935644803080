import React from 'react'
import * as Yup from 'yup'

import { Box, FormGroup, Label, Paragraph } from '@allied-solutions/affinity'

import { FormLayout, Hint } from '../../../components'

const initialValues = {
  InsuranceCompanyName: '',
  PolicyNumber: '',
  AgentName: '',
  AgentTelephone: '',
  AgentEmailAddress: '',
  IsAgent: false,
}

const validationSchema = {
  InsuranceCompanyName: Yup.string().required('Please fill out this field.'),
  PolicyNumber: Yup.string().required('Please fill out this field.'),
  AgentName: Yup.string(),
  AgentTelephone: Yup.string().matches(
    /\([1-9]{1}\d{2}\) \d{3}-\d{4}/,
    'Please enter a valid US phone number.'
  ),
  AgentEmailAddress: Yup.string().email('Please enter a valid email address.'),
  IsAgent: Yup.boolean(),
}

const Fields = ({ values }) => (
  <>
    <FormGroup id="InsuranceCompanyName" mt={9} required>
      <FormGroup.Label
        visible
        hint={
          <Hint
            id="Tooltip--whatsThis"
            content="This is also known as your Insurance Carrier. Enter the name of the company on your insurance policy. This should not be confused with the name of your insurance agent or broker."
          />
        }
      >
        Insurance Company Name
      </FormGroup.Label>
      <FormGroup.InputGroup>
        <FormGroup.InputGroup.Input />
      </FormGroup.InputGroup>
      <FormGroup.Caption />
    </FormGroup>

    <FormGroup id="PolicyNumber" mt={8} required>
      <FormGroup.Label
        visible
        hint={
          <Hint
            id="Tooltip--whatsThis--policy"
            content="This number may also be called your Plan Number or Plan ID. It can be found on your insurance card."
          />
        }
      >
        Insurance Policy Number
      </FormGroup.Label>

      <FormGroup.InputGroup>
        <FormGroup.InputGroup.Input />
      </FormGroup.InputGroup>
      <FormGroup.Caption />
    </FormGroup>

    <Box
      display={[null, 'flex', 'block', 'block', 'flex']}
      justifyContent="space-between"
    >
      <FormGroup
        id="AgentName"
        mt={8}
        width={[null, '48%', '100%', '100%', '48%']}
      >
        <FormGroup.Label visible>Agent Name</FormGroup.Label>
        <FormGroup.InputGroup>
          <FormGroup.InputGroup.Input />
        </FormGroup.InputGroup>
        <FormGroup.Caption />
      </FormGroup>

      <FormGroup
        id="AgentTelephone"
        mt={8}
        width={[null, '48%', '100%', '100%', '48%']}
      >
        <FormGroup.Label visible>Agent Phone Number</FormGroup.Label>
        <FormGroup.InputGroup>
          <FormGroup.InputGroup.Phone
            placeholder="(XXX) XXX-XXXX"
            guide={false}
          />
        </FormGroup.InputGroup>
        <FormGroup.Caption />
      </FormGroup>
    </Box>

    <FormGroup id="AgentEmailAddress" mt={8}>
      <FormGroup.Label visible>Agent Email Address</FormGroup.Label>
      <FormGroup.InputGroup>
        <FormGroup.InputGroup.Input type="email" />
      </FormGroup.InputGroup>
      <FormGroup.Caption />
    </FormGroup>

    <FormGroup id="IsAgent" mt={8}>
      <FormGroup.Checkbox
        value={true}
        label="I am filling this out as an insurance agent"
        checked={values.IsAgent}
        css={`
          &:focus-within {
            ${Label} {
              & > span:first-child {
                outline: 3px solid #85cffe;
                color: ${props => props.theme.colors.blue800} !important;
                border-radius: 3px;
              }
            }

            span {
              &[class*='StyledCheckboxChecked'] {
                outline: 3px solid #85cffe;
                color: ${props => props.theme.colors.blue800} !important;
                border-radius: 3px;
              }
            }
          }
        `}
      />
    </FormGroup>
  </>
)

const ProviderInfoPage = ({ location }) => {
  return (
    <FormLayout
      leftSide={{
        title: 'Enter your insurance provider details.',
        body: (
          <>
            <Paragraph typeStyle="bodyMedium" textAlign="left" color="gray600">
              Check your insurance policy's declarations page and find the
              insurance provider information.
            </Paragraph>
            <Paragraph typeStyle="bodyMedium" textAlign="left" color="gray600">
              Do you have an insurance agent? If so, it can speed up the
              verification process. Don't worry if you're not sure.
            </Paragraph>
          </>
        ),
      }}
      rightSide={{
        title: 'Insurance Provider',
        fields: props => <Fields values={props.values} />,
      }}
      {...{ location, initialValues, validationSchema }}
    />
  )
}

export default ProviderInfoPage
export { initialValues, validationSchema, Fields }
